<template>
  <common-header />
  <div class="account-container">
    <el-affix :offset="20">
      <el-aside>
        <client-only>
          <el-menu :default-active="defaultActive">
            <nuxt-link v-for="(item, index) in menuList" :key="index" :to="{ name: item.name }">
              <el-menu-item :index="item.name"> <img :src="item.icon" class="img-icon" /> {{ item.title }} </el-menu-item>
            </nuxt-link>
          </el-menu>
        </client-only>
      </el-aside>
    </el-affix>
    <el-main>
      <div class="account-main">
        <slot />
      </div>
    </el-main>
  </div>
</template>
<script setup>
  import course from 'assets/svg/account/course.svg'
  import order from 'assets/svg/account/order.svg'
  import user from 'assets/svg/account/user.svg'
  import exam from 'assets/svg/exam.svg'

  import { indexApi } from '~/api'
  const { data } = await useAsyncData('website', async () => {
    return indexApi.websiteInfo()
  })
  useHead({
    link: [{ rel: 'icon', type: 'image/x-icon', href: data.value?.websiteIcon }],
    title: '用户中心' + (data.value?.websiteName ? ' - ' + data.value?.websiteName : '')
  })

  const route = useRoute()
  const defaultActive = ref(route.name)

  const menuList = [
    {
      name: 'account-course',
      icon: course,
      title: '我的课程'
    },
    {
      name: 'account-exam',
      icon: exam,
      title: '我的考试'
    },
    {
      name: 'account-order',
      icon: order,
      title: '我的订单'
    },
    {
      name: 'account-user',
      icon: user,
      title: '个人信息'
    }
  ]
</script>
<style lang="scss" scoped>
  .el-aside {
    width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 10px;
    ul {
      border-right: none;
      padding: 10px;
      border-radius: 10px;
      li {
        margin: 10px;
      }
      .is-active {
        //background: #eee;
        border-radius: 5px;
        color: blueviolet;
      }
    }
    .img-icon {
      width: 20px;
      margin-right: 5px;
    }
  }
  .account-main {
    flex: 1;
    padding: 20px 20px 0;
    background: #fff;
    border-radius: 5px;
  }
  .account-container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    :deep(.el-main) {
      margin: 20px auto;
      padding: 0;
    }
  }
</style>
